@font-face {
  font-family: "Poppins";
  src: url("../src/source/poppins.otf");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

section {
    min-height: 100vh;
    width: 100%;
}

html {
    user-select: none;
    background-color: #fffcbe;
  }

body {
    min-height: 100vh;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

h1 {
    font-size: clamp(3rem, 8vw, 10rem);
    transform: rotate(-3deg) skew(1deg);
    padding-left: .8em; 
    
}

h2 {
    font-size: clamp(2rem, 5vw, 4rem);
    transform: rotate(-3deg) skew(1deg) translateX(2.5em) translateY(-0.8em);
    padding-left: .4em;
    padding-right: .4em; 
    text-shadow: -0.2vh -0.2vh 0 #3b3b3b;
}


h3 {
    font-size: clamp(2rem, 7vw, 8rem);
    transform: rotate(-1deg) skew(1deg);

    filter: drop-shadow(-0.2vh -0.2vh 0 #3b3b3b);
}

h4 {
    font-size: clamp(3rem, 5vw, 10rem);

    transform: rotate(-1deg) skew(1deg);
    line-height: 100%;
}

h5 {
    font-size: clamp(2rem, 5vw, 10rem);

    transform: rotate(-1deg) skew(1deg);
    line-height: 100%;
}

h6 {
    font-size: clamp(2rem, 2.5vw, 10rem);
    transform: rotate(-1deg) skew(1deg);
    line-height: 100%;
}


p {
    font-size: clamp(2rem, 4.5vw, 10rem);
    transform: rotate(-3deg) skew(2deg);
    line-height: 100%;
}

nav {
    font-size: clamp(1.5rem, 2.5vw, 10rem);
}

#navbutton {
    visibility:visible;
    text-shadow:
    -1.5px -1.5px 0 #242022,
     0   -1.5px 0 #242022,
     1.5px -1.5px 0 #242022,
     1.5px  0   0 #242022,
     1.5px  1.5px 0 #242022,
     0    1.5px 0 #242022,
    -1.5px  1.5px 0 #242022,
    -1.5px  0   0 #242022;
    border-width: 1.5px;
}

.dropdownmenu.active{
    display: block;
    transition: var(--speed) ease;
}

.dropdownmenu.inactive{
    opacity:0;
    display: none;
    transition: var(--speed) ease;
}

#imgtrf{
    transform:rotate(-1deg) skew(1deg);
    filter: drop-shadow(-0.5vh -0.3vh 0 #3b3b3b);
    
}

#imgtrf2{
    filter: drop-shadow(-0.5vh -0.3vh 0 #3b3b3b);
    
}

#ultrf{
    transform:rotate(-1deg) skew(1deg);
}

#litrf{
    transform:rotate(-1deg) skew(1deg);
    filter: drop-shadow(-0.1vh -0.1vh 0 #3b3b3b);
}

#litrf:hover {
    transform: scale(1.05) rotate(-1deg) skew(1deg);
}


#highlight {
    font-size: clamp(3rem, 4vw, 10rem);

}

#highlight1 {
    font-size: clamp(3rem, 6vw, 10rem);

}

#highlight2 {
    font-size: clamp(3rem, 6vw, 10rem);

}

#mainpageleft {
    visibility: hidden;
}

#desktopnav {
    display: none;

}

#pickle3{
    display:none;
}

#dropdowntext {
    font-size: clamp(1.6rem, 4vw, 10rem);
}
@media only screen and (min-width: 300px) {
    ul {
        text-shadow:
        -1.5px -1.5px 0 #242022,
         0   -1.5px 0 #242022,
         1.5px -1.5px 0 #242022,
         1.5px  0   0 #242022,
         1.5px  1.5px 0 #242022,
         0    1.5px 0 #242022,
        -1.5px  1.5px 0 #242022,
        -1.5px  0   0 #242022;
    }

    #stroke {
        text-shadow:
        -1.5px -1.5px 0 #242022,
         0   -1.5px 0 #242022,
         1.5px -1.5px 0 #242022,
         1.5px  0   0 #242022,
         1.5px  1.5px 0 #242022,
         0    1.5px 0 #242022,
        -1.5px  1.5px 0 #242022,
        -1.5px  0   0 #242022;
        
    }

    #gotomint {
        font-size: clamp(2rem, 3vw, 4rem);
        transform: rotate(-3deg) skew(1deg) translateX(2.5em) translateY(-0.8em);
        padding-left: .4em;
        padding-right: .4em; 
        text-shadow: -0.2vh -0.2vh 0 #3b3b3b;
        text-shadow:
        -1.5px -1.5px 0 #242022,
         0   -1.5px 0 #242022,
         1.5px -1.5px 0 #242022,
         1.5px  0   0 #242022,
         1.5px  1.5px 0 #242022,
         0    1.5px 0 #242022,
        -1.5px  1.5px 0 #242022,
        -1.5px  0   0 #242022; 
        border-width: 1.5px;
    }
    
    #gotomint:hover {
        transform: scale(1.03) rotate(-3deg) translateX(2.5em) translateY(-0.8em);
    }

    #joinguild{
        font-size: clamp(2rem, 5vw, 4rem);
        transform: rotate(-1deg) skew(1deg);
        padding-left: .4em;
        padding-right: .4em; 
    
        text-shadow:
        -1.5px -1.5px 0 #242022,
         0   -1.5px 0 #242022,
         1.5px -1.5px 0 #242022,
         1.5px  0   0 #242022,
         1.5px  1.5px 0 #242022,
         0    1.5px 0 #242022,
        -1.5px  1.5px 0 #242022,
        -1.5px  0   0 #242022;
        border-width: 1.5px;
    }
    
    
    #joinguild:hover {
        transform: scale(1.05) rotate(-1.5deg);
    }
 }



 @media only screen and (min-width: 720px) {
    #pickle3{
        visibility: hidden;
    }

    .dropdownmenu{
        visibility: hidden;
    }
    #mainpageleft {
        visibility: visible;
    }

    #navbutton {
        display: none;
    }

    #litrf{
        transform:rotate(-1deg) skew(1deg);
        filter: drop-shadow(-0.4vh -0.2vh 0 #3b3b3b);
    }

    ul {
        text-shadow:
        -1.7px -1.7px 0 #242022,
         0   -1.7px 0 #242022,
         1.7px -1.7px 0 #242022,
         1.7px  0   0 #242022,
         1.7px  1.7px 0 #242022,
         0    1.7px 0 #242022,
        -1.7px  1.7px 0 #242022,
        -1.7px  0   0 #242022;
    }
    

    #desktopnav {
        display: flex;
        text-shadow:
        -1.7px -1.7px 0 #242022,
         0   -1.7px 0 #242022,
         1.7px -1.7px 0 #242022,
         1.7px  0   0 #242022,
         1.7px  1.7px 0 #242022,
         0    1.7px 0 #242022,
        -1.7px  1.7px 0 #242022,
        -1.7px  0   0 #242022;
    }


    #stroke {
        text-shadow:
        -1.7px -1.7px 0 #242022,
         0   -1.7px 0 #242022,
         1.7px -1.7px 0 #242022,
         1.7px  0   0 #242022,
         1.7px  1.7px 0 #242022,
         0    1.7px 0 #242022,
        -1.7px  1.7px 0 #242022,
        -1.7px  0   0 #242022;
        
    }

    #gotomint {
        font-size: clamp(2rem, 3vw, 4rem);
        transform: rotate(-3deg) skew(1deg) translateX(2.5em) translateY(-0.8em);
        padding-left: .4em;
        padding-right: .4em; 
        text-shadow: -0.2vh -0.2vh 0 #3b3b3b;
        text-shadow:
        -1.7px -1.7px 0 #242022,
         0   -1.7px 0 #242022,
         1.7px -1.7px 0 #242022,
         1.7px  0   0 #242022,
         1.7px  1.7px 0 #242022,
         0    1.7px 0 #242022,
        -1.7px  1.7px 0 #242022,
        -1.7px  0   0 #242022; 
        border-width: 1.7px;
    }
    
    #gotomint:hover {
        transform: scale(1.03) rotate(-3deg) translateX(2.5em) translateY(-0.8em);
    }

    #joinguild{
        font-size: clamp(2rem, 5vw, 4rem);
        transform: rotate(-1deg) skew(1deg);
        padding-left: .4em;
        padding-right: .4em; 
    
        text-shadow:
        -1.7px -1.7px 0 #242022,
         0   -1.7px 0 #242022,
         1.7px -1.7px 0 #242022,
         1.7px  0   0 #242022,
         1.7px  1.7px 0 #242022,
         0    1.7px 0 #242022,
        -1.7px  1.7px 0 #242022,
        -1.7px  0   0 #242022;
        border-width: 1.7px;
    }
    
    
    #joinguild:hover {
        transform: scale(1.05) rotate(-1.5deg);
    }
 }

 @media only screen and (min-width: 1200px) {
    ul {
        text-shadow:
        -2px -2px 0 #242022,
         0   -2px 0 #242022,
         2px -2px 0 #242022,
         2px  0   0 #242022,
         2px  2px 0 #242022,
         0    2px 0 #242022,
        -2px  2px 0 #242022,
        -2px  0   0 #242022;
    }

    #stroke {
        text-shadow:
        -2px -2px 0 #242022,
         0   -2px 0 #242022,
         2px -2px 0 #242022,
         2px  0   0 #242022,
         2px  2px 0 #242022,
         0    2px 0 #242022,
        -2px  2px 0 #242022,
        -2px  0   0 #242022;
    }

    

    #desktopnav {
        visibility: visible;
        text-shadow:
        -2px -2px 0 #242022,
         0   -2px 0 #242022,
         2px -2px 0 #242022,
         2px  0   0 #242022,
         2px  2px 0 #242022,
         0    2px 0 #242022,
        -2px  2px 0 #242022,
        -2px  0   0 #242022;
    }

    #gotomint {
        font-size: clamp(2rem, 3vw, 4rem);
        transform: rotate(-3deg) skew(1deg) translateX(2.5em) translateY(-0.8em);
        padding-left: .4em;
        padding-right: .4em; 
        text-shadow: -0.2vh -0.2vh 0 #3b3b3b;
        text-shadow:
        -2px -2px 0 #242022,
         0   -2px 0 #242022,
         2px -2px 0 #242022,
         2px  0   0 #242022,
         2px  2px 0 #242022,
         0    2px 0 #242022,
        -2px  2px 0 #242022,
        -2px  0   0 #242022; 
        border-width: 2px;
    }
    
    #gotomint:hover {
        transform: scale(1.03) rotate(-3deg) translateX(2.5em) translateY(-0.8em);
    }

    #joinguild{
        font-size: clamp(2rem, 5vw, 4rem);
        transform: rotate(-1deg) skew(1deg);
        padding-left: .4em;
        padding-right: .4em; 
    
        text-shadow:
        -2px -2px 0 #242022,
         0   -2px 0 #242022,
         2px -2px 0 #242022,
         2px  0   0 #242022,
         2px  2px 0 #242022,
         0    2px 0 #242022,
        -2px  2px 0 #242022,
        -2px  0   0 #242022;
    }
    
    
    #joinguild:hover {
        transform: scale(1.05) rotate(-1.5deg);
    }
    
 }




